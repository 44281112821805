import { equals } from 'ramda'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

type ToBeDebounced<T extends any[]> = (...args: T) => void
export const useDebouncedFn = <T extends any[]>(fn: ToBeDebounced<T>, delay = 500) => {
  const timer = useRef<ReturnType<typeof setTimeout>>()

  const debouncedFn = useCallback(
    (...args: T) => {
      if (timer.current) {
        clearTimeout(timer.current)
      }

      timer.current = setTimeout(() => {
        fn(...args)
      }, delay)
    },
    [delay, fn],
  )

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    },
    [],
  )

  return debouncedFn
}

export const useDebouncedChange = <T extends any>(value: T, onChange: (value: T) => void, delay = 500) => {
  const [state, setState] = useState(value)
  const debouncedOnChange = useDebouncedFn(onChange, delay)

  const handleOnChange = useCallback(
    (newValue: T) => {
      setState(newValue)
      debouncedOnChange(newValue)
    },
    [debouncedOnChange],
  )

  return [state, setState, handleOnChange] as const
}

// only update state if the originalState is different (ramda's equals is deep comparison) with the current state
export const useDeepState = <T>(originalState: T): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(originalState)

  useEffect(() => {
    setState((s) => {
      if (!equals(s, originalState)) {
        return originalState
      }
      return s
    })
  }, [originalState])

  return [state, setState]
}

export const usePrevious = (value: any) => {
  const ref = useRef<any>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export const cannot = (part: never): never => {
  throw new Error('cannot happen: ', { cause: part })
}
